import React from "react";
import Logo from "./logo/Plevel_design_horizontal.png";
import Title from "./components/title.js";
import SubTitle from "./components/subtitle.js";
import Paragraph from "./components/paragraph.js";
import Photo from "./components/photo.js";
import Divisor from "./components/divisor.js";
import Logostyle from "./components/logo.js";
import List from "./components/list";
import Gallery from './components/gallery.js';
import Navigation from './components/navigation.js';


function App() {
  
  
  
  
  const about = [
    {
      heading: "O mně",
      subheading: null,
      text: "Jmenuji se Anežka a přírodnímu designu se věnuji od roku 2012. Pracuji s různými velikostmi pozemků. U všech projektů je mým cílem přírodě blízké řešení odolné vůči klimatické změně, které není jen funkční, ale má také designovou pointu. Ráda pracuji také s permakulturními prvky a principy. Při spolupráci s obcemi zahrnuji dílčí návrhy do širšího kontextu, kdy se zaměřuji také na propojenost s dalšími prvky a často opomíjenou prostupnost krajiny.", 
      photo: null,
    },
    {
      heading: null,
      subheading: null,
      text: "Na konzultace vyrážím z Kozlovic (okres Frýdek-Místek). U vzdálenějších lokalit se snažím spojovat více zakázek do jednoho dne, abych šetřila náklady na dopravu i přírodu. Nebráním se výjezdům i mimo Moravu, jen tato setkání potřebuji plánovat více dopředu.", 
      photo: null,
    },
  ];
  
  const listofservice = [
    {
      heading: null,
      subheading: "Čím konkrétně se zabývám:",
      text: null, 
      photo: null,
    },

  ];

    const colab = [
    {
      heading: null,
      subheading: "Jak funguje spolupráce?",
      text: "Po prvním kontaktu, kdy sladíme vaše požadavky a mé možnosti si domluvíme schůzku na pozemku. Zde proběhne první konzultace, která zpravidla zabere 1-2hod a probereme podrobně vaši vizi, doptám se na všechny možné vstupující faktory i časové možnosti. Do 2 dnů pošlu emailem návrh finančního rámce a termínu dodání zpracovaného projektu a doplňujících dokumentů. Některá spolupráce končí zasláním projektu a faktury, jiné trvají třeba i několik let a setkáváme se pravidelně na pozemku, kde dělám odbornou údržbu (prořezávka dřevin, kontrola škůdců a patogenů,…) a dávám tipy jak design ještě vylepšit s ohledem na stávající stav. Je možné se také domluvit na dozoru při realizaci, zejména v té počáteční fázi. Mohu např. ukázat jak správně vysadit strom a jaká je jeho následná péče, vykolíkovat prostor pro výsadbu, ukázat na jaké věci si zejména dát pozor apod.", 
      photo: null,
    },
  ];
    const experience = [
      {
        heading: null,
        subheading: "Dosavadní vzdělání, zkušenosti a spolupráce:",
        text: null, 
        photo: "/photos/Anezka/anešaupravena.jpg",
      },
    ];
    const whyplevel = [
      {
        heading: null,
        subheading: null,
        text: "A proč zrovna Plevel design? Plevel má v přírodě nezastupitelnou funkci. Nepřistupuji k němu jen jako ke škůdci, ale jako k součásti přirozeného systému. Dá se využít ke spoustě praktických postupů - bioindikaci, hygienizaci, zúrodňování, mulčování, podpoře ostatních plodin, jako náhrada trávníku a v neposlední řadě také ke zpestření jídelníčku.", 
        photo: null,
      },
    ];


  const service = [
    {
      heading: "Zpracování projektu",
      text: "Zpracování projektu zahrnuje analýzu lokality, osazovací plán, správné postupy při samorealizaci, rozvržení prací, popis následné péče a konzultace na dálku po dobu 1 roku od odevzdání projektu: Cena se odvíjí od velikosti pozemku a náročnosti požadavků. U kompletního návrhu zahrady do 1000 m2 se cena orientačně pohybuje v rozmezí 15 000 - 20 000 Kč. ",
    },
    {
      heading: "Konzultace",
      text: "Konzultace bez vypracování projektu: 900 Kč/hod + doprava 6 Kč/km.",
    },
    {
      heading: "Řez stromů",
      text: "V předjaří (cca únor-duben) se každoročně věnuji také výchovnému řezu mladých stromů a zmlazujícímu řezu vzrostlých starých stromů, zejména ovocných. Do koruny stromů však musí být možnost dostat se po žebříku či bez komplikací po větvích v bezpečné výšce. Nejsem akreditovaný stromolezec. Cena 900 Kč/hod + doprava 6Kč/km.",
    },

  ];

  const portfolio = [
    {
      location: "Naše zahrada Chotětov 2019",
      description:
        "V roce 2019 jsme se nastěhovali do domu, který měl místo zahrady pouze 350m2 staveniště. Postupně jsme jej začali svépomocí předělávat v místo plné života. Přibylo k nám druhé a třetí dítě, oba s mužem pracujeme, takže vysoká míra bezúdržbovosti a samoregulace je pro nás zásadní.",
      photo: [
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_1.jpg",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_2.jpg",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_3.jpg",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_4.JPG",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_5.JPG",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_6.JPG",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_7.JPG",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_8.JPG",
          legend: "Chotětov 2019",
        },
        {
          src: "/photos/portfolio/Naše zahrada Chotětov 2019/Chotetov_nase_9.JPG",
          legend: "Chotětov 2019",
        },
      ],
    },
    {
      location: "LIBEREC 2020",
      description:
        "U této zahrady bylo potřeba nejprve vyřešit prudký svah a jeho následné zpevnění. Zahrada měla být hodně orientovaná na děti a samozásobitelství, ale zároveň zde nemohly být žádné alergie působící rostliny, což byl trošku oříšek. Nakonec se nám podařilo vymyslet funkční kompromis pro všechny uživatele zahrady. ",
      photo: [
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_1.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_2.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_3.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_4.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_5.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_6.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_7.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_8.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_9.jpeg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_10.jpg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_11.jpg",
          legend: "Liberec 2020",
        },
        {
          src: "/photos/portfolio/Liberec 2020/Liberec_12.jpeg",
          legend: "Liberec 2020",
        },
      ],
    },
    {
      location: "Lesní klub Mondík 2021",
      description:
        "Se spolkem Mondík jsem začala spolupracovat v roce 2019. Mají v pronájmu kus farní zahrady pro své aktivity (zejména pobyt dětí z lesního klubu 3 dny v týdnu). Zahrada byla velmi zarostlá, postupně se kultivovala, avšak kvůli velmi prudkému svahu bylo potřeba udělat větší zásahy. Ty jsem měla na starosti já. Bez větší finanční injektáže by to bohužel nešlo, proto jsme společně zažádali o dotace na \"Zelené oázy\" nadace Partnerství. Na jaře 2021 nám dotaci přidělili a pustili jsme se do práce. Bohužel kvůli protiepidemickým opatřením byly akce a brigády v jiném formátu než jsme si představovali, nakonec jsme ale do konce roku vše stihli dokončit za pomocí 2 brigádníků.",
      photo: [
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_01.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_02.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_03.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_04.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_05.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_06.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_07.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_08.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_09.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_10.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_11.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_12.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_13.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_14.jpg",
          legend: "Benátky nad Jizerou 2021",
        },
        {
          src: "/photos/portfolio/Lesní klub Mondík 2021/Mondik_15.jpg",
          legend: "Benátky nad Jizerou 2021",
        },

      ],
    },
    {
      location: "DOLNÍ BOUSOV 2020",
      description:
        "Klasické zadání: zahrada cca 1000m2 u novostavby, momentálně celá rozkopaná bez jakékoli zeleně. A přeci je každá jiná! Tato je specifická precizním provedením - samorealizace - a potřebou upraveného pocitu ze zahrady. I takto může v praxi vypadat přírodní design!",
      photo: [
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_01.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_02.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_03.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_04.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_05.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_06.jpg",
          legend: "Dolní Bousov 2020",
        },
        {
          src: "/photos/portfolio/Ječná Dolní Bousov 2021/Bousov_07.jpg",
          legend: "Dolní Bousov 2020",
        },
        
      ],
    },
    {
      location: "CHOTĚTOV 2020",
      description:
        "Spolupráce na této zahradě byla specifická v tom, že měli zahradu již několik let založenou. Nějakou dobu už se v ní ale necítili dobře, něco jí chybělo. Proto jsme se sešli, probrali způsob jakým ji momentálně využívají a co by se mělo změnit do budoucna. Naprosto klíčovým prvkem bylo nově založené koupací jezírko (celoročně, jsou to nadšení otužilci). Původní vize byl jen smáčecí sud, ale nakonec jsme se dohodli na jezírku 6x4m a byla to správná volba. Přidali jsme také skalku pro bylinky a schování poklopů pro dešťovku, na celou zahradu pak obrubníky a plošný mulč plný celoročně kvetoucích trvalek. K tomu také 2 vysokokmeny a dominantní vícekmen javoru k jezírku doplněný barevnou lavičkou - zde jsem musela chvíli přesvědčovat, ale jsem ráda, že jsem byla úspěšná. :)",
      photo: [
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_1.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_2.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_3.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_3.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_4.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_5.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_6.jpeg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_7.jpg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_8.jpg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_9.jpg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_10.jpg",
          legend: "Chotětov 2020",
        },
        {
          src: "/photos/portfolio/Chotětov 2020/Chotetov_11.JPG",
          legend: "Chotětov 2020",
        },       
      ],
    },
    {
      location: "Diplomová práce - Hospodářství LÍŠNICE",
      description:
        "Spolupráce s Víťou a Luckou Kulhánkovými započala v roce 2013, kdy jsem hledala pozemek vhodný pro analýzu a vytvoření projektového permakulturního designu pro mou diplomovou práci. Kromě pozemku jsem našla také úžasně inspirativní lidi, se kterými se vídám až do současnosti. Jejich pragmatický přístup k permakultuře a postupné realizaci byl jako stvořený k racionálnímu akademickému postupu. Pozemek má rozlohu 3,2 ha, z toho 0,8 ha tvoří rybník. Zbytek pozemku se nachází na JZ svahu a v minulosti byl využíván zpočátku jako pole, později jako trvalý travní porost s šetrným obhospodařováním. Záměrem majitelů je se na pozemek v horizontu 10 let přestěhovat. V plánu je vybudování ekologického domu zapuštěného do svahu a užitkové zahrady/usedlosti se zaměřením na soběstačnost jak v rostlinné tak živočišné produkci. Podrobněji viz diplomová práce - na vyžádání prostřednictvím emailu. Na pozemku byla také v roce 2015 uspořádaná praktická část mezinárodní letní škola Permaculture and Science (fotky viz speciální složka).",
      photo: [
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_1.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_2.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_3.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_4.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_5.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_6.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_7.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_8.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_9.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_10.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_11.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_12.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_13.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_14.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_15.jpg",
          legend: "Líšnice 2014 - současnost",
        },
        {
          src: "/photos/portfolio/Líšnice 2016/Lisnice_16.jpg",
          legend: "Líšnice 2014 - současnost",
        },
      ],  
    },
    {
      location: "KOZLOVICE 2012",
      description:
        "Experimentální pozemek u mých rodičů, o rozloze cca 1000m2. Zeleninové vyvýšené záhony, bylinková spirála, podpůrné byliny a keře u ovocných stromů, zakomponování pařeniště, trojkomorový kompostér. Jedlé stromy, keře, byliny, houby. Tříletý pokus o přírodní jezírko nakonec skončil ztrátou nervů a vyložením ekologickou jezírkovou folií - ne každý má štěstí na dostatečnou jílovitou složku v půdě.",
      photo: [
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_1.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_2.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_3.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_4.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_5.jpg",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_6.jpg",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_7.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_8.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_9.JPG",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_10.jpg",
          legend: "Kozlovice 2012 - současnost",
        },
        {
          src: "/photos/portfolio/Kozlovice 2012/Kozlovice_11.jpg",
          legend: "Kozlovice 2012 - současnost",
        },    
      ],
    },
    {
      location: "Realizace sadu JABLONNÉ V PODJEŠTĚDÍ 2019",
      description:
        "Postupná realizace pozemku o rozloze 8000m2 ve svahu na úpatí Jizerských hor. V prudkém svahu bylo nutné vybudování svejlů pro zadržování vody i živin pro ovocné stromy. Zároveň zde byla velká limitace elektrickým vedením nad samotným sadem.",
      photo: [
        {
          src: "/photos/portfolio/Realizace sadu Jablonné v Podještědí 2019/Podjestedi_1.JPG",
          legend: "Jablonné v Podještědí",
        },
        {
          src: "/photos/portfolio/Realizace sadu Jablonné v Podještědí 2019/Podjestedi_2.JPG",
          legend: "Jablonné v Podještědí",
        },
        {
          src: "/photos/portfolio/Realizace sadu Jablonné v Podještědí 2019/Podjestedi_3.JPG",
          legend: "Jablonné v Podještědí",
        },
        {
          src: "/photos/portfolio/Realizace sadu Jablonné v Podještědí 2019/Podjestedi_4.JPG",
          legend: "Jablonné v Podještědí",
        },
        {
          src: "/photos/portfolio/Realizace sadu Jablonné v Podještědí 2019/Podjestedi_5.JPG",
          legend: "Jablonné v Podještědí",
        },
      ],
    },
    {
      location: "Project ECLS kompletní návrh farmy 2015",
      description:
        "Case study competition zrealizovaná v rámci ČZU v roce 2015. V rámci půlroku se mezinárodní týmy studentů zaobíraly konkrétní farmou v obci Klášterní Skalice o rozloze cca 98ha. Během několikaměsíční teoretické přípravy a následně týdenního pobytu na farmě bylo úkolem vypracovat kompletní plán pro inovace farmy (rostlinná i živočišná produkce) na několik let dopředu včetně financování, marketingu a vlastní značky. Výsledky pak byly prezentovány na konferenci v Praze, kde naše skupina vyhrála studentskou cenu.",
      photo: [
        {
          src: "/photos/portfolio/ECLS case study competition 2015/ECLS_2.jpg",
          legend: "Klášterní Skalice",
        },
        {
          src: "/photos/portfolio/ECLS case study competition 2015/ECLS_3.jpg",
          legend: "Klášterní Skalice",
        },
        {
          src: "/photos/portfolio/ECLS case study competition 2015/ECLS_4.jpg",
          legend: "Klášterní Skalice",
        },
      ],
    },
    {
      location: "Letní škola Permaculture and Science 2015",
      description:
        "Mnou organizovaná letní škola na ČZU s mezinárodními lektory i účastníky. 27. 7. - 2. 8. 2015. Teoretická část proběhla na České zemědělské univerzitě, praktická pak no pozemku v Líšnici u manželů Kulhánkových (viz Hospodářství Líšnice).",
      photo: [
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_1.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_2.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_3.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_4.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_5.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_6.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_7.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_8.JPG",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_9.jpg",
          legend: null,
        },
        {
          src: "/photos/portfolio/Permaculture and Science 2015/Perma_science_10.JPG",
          legend: null,
        },
      ],
    },
    {
      location: "HORNÍ LOUČKY 2015",
      description:
        "Zahrada pro malé děti, samozásobitelství a odpočinek s využitím velkého množství permakulturních prvků.",
      photo: [
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_01.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_02.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_03.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_04.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_05.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_06.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_07.jpg",
          legend: "Horní Loučky",
        },
        {
          src: "/photos/portfolio/Horní Loučky 2016/Loucky_08.jpg",
          legend: "Horní Loučky",
        },
      ],
    },
    {
      location: "LÍSKOVEC 2013",
      description:
        "Pozemek o rozloze cca 1,5ha - 0,5ha užitková zahrada a 1ha pastvina s jedlými keři a stromy. Valná část pozemku se nachází na jižním svahu, uprostřed něhož je vybudovaná vodní plocha napájená dešťovou vodou ze střechy výše položeného domu. Na pozemku je jílovité podloží a umístění bylo vybráno optimálně, jezírko tedy bylo zcela přírodní a samoregulované.",
      photo: [
        {
          src: "/photos/portfolio/Lískovec 2013/Liskovec_1.JPG",
          legend: "Lískovec",
        },
        {
          src: "/photos/portfolio/Lískovec 2013/Liskovec_2.JPG",
          legend: "Lískovec",
        },
        {
          src: "/photos/portfolio/Lískovec 2013/Liskovec_3.JPG",
          legend: "Lískovec",
        },
        {
          src: "/photos/portfolio/Lískovec 2013/Liskovec_4.JPG",
          legend: "Lískovec",
        },
        {
          src: "/photos/portfolio/Lískovec 2013/Liskovec_5.jpg",
          legend: "Lískovec",
        },
      ],
    },
  ];

  const reference = [
    {
      heading: "",
      text: "Od roku 2013 probíhala práce na našem domě, po 3 letech bydlení však byla zahrada stále v havarijním stavu a začali jsme tušit, že si s ní sami neporadíme. Koncept klasické architektem navržené zahrady se sestříhaným trávníkem nás neoslovil, naopak permakulturou jsme byli nadšeni. Rozhodli jsme se oslovit odborníka, což se ukázalo jako problém, jelikož projektantů zabývajících se trvale udržitelným pěstitelstvím je jak hub po dešti. O Anežce jsem věděla už nějakou dobu, nicméně nás dělila velká vzdálenost, prakticky přes celou republiku. Nakonec jsem ji kontaktovala s prosbou o projekt a k mému překvapení souhlasila. První email jsme si vyměnily v červnu 2016 a od té doby věci nabraly rychlý spád. V červenci proběhla první návštěva, při níž Anežka zmapovala terén, a upřesnily se požadavky. Na začátku září byl hotový projekt. Rozhodli jsme se pro osázení pozemku odrůdami, které jsou typické pro náš region. Vzhledem k nekvalitní půdě, navrhla Anežka vyvýšené záhony netradičního tvaru, díky nimž jsme se stali u nás na vsi senzací. Nebyl tedy opomenut ani estetický prvek, s primárně užitnou funkcí. A to je skutečnost, kterou oceňuji asi nejvíce, a to že naše zahrada bude prakticky kompletně jedlá, přesto líbivá a řekněme v našich končinách netradiční. Návrh zahrady je precizně zpracovaný, jeho součástí je pozemková studie, mapa pozemku s rozmístěním rostlin, ke každé plodině je sepsaný stručný návod k pěstování, rostliny, které podporují růst, rozpis doporučených činností na dva roky dopředu. Pro mě je projekt jednou z nejlepších investic. Velmi oceňuji Anežčinu vstřícnost a ochotu vyhovět. Spolupráce s ní byla radost a prvotní nadšení přetrvává nadále.",
      name: "Jana Midrlová",
    },
    {
      heading: "",
      text: "V létě 2015 jsem se účastnila letní školy o permakultuře, kterou Anežka pořádala ve spolupráci s ČZU a lektorkou z Nizozemí. Na akademické půdě to byl odvážný počin, do kterého byli zapojeni lektoři z akademického světa i oboru permakultury, měli jsme tedy možnost získat informace z velmi různých zdrojů a vytvořit si kontakty napříč různými skupinami. Zajímavou formou nám byly vysvětleny teoretické základy, které jsme měli možnost pak sami aplikovat při návrhu permakulturního hospodářství a vlastní návrhy konzultovat s odborníky. Anežku jako odbornici tématu i jako osobu mohu ke spolupráci vřele doporučit :)",
      name: "Markéta Mikovcová",
    },
    {
      heading: "",
      text: "Žijeme na vesnici už 28 let a naše zahrada za tu dobu prošla mnohými změnami. Vlastně se dá říci, že jsme se dlouho pokoušeli o symbiózu zeleninových záhonů a zahrady s parkovou úpravou. Pak se nám náhodně dostala do rukou knížka Jaroslava Svobody o permakutuře. A ta nás dostala! Tedy ta permakultura. Následující jaro jsme nastoupili na zeleninové záhonky s nadšením a představou, že je osadíme s náležitou rozmanitostí. Výsledkem nebyly vysoké výnosy, ale naše ponaučení, že pokud záhon náležitě podsejeme pohankou, tak tam prostě bude jen pohanka. Podobných experimentů jsme udělali několik. Měli sice poučný závěr, ale ten nás moc nemotivoval. Tak jsme se svěřili do rukou odborníka. S Anežčinou pomocí jsme změnili zahradu od základů. Zmizela parková úprava pozemku.  Většina zeleninových záhonů je vyvýšená (nemusíme plít cestičky a ohýbat se). Mají specificky tvar , který podporuje růst rostlin. Anežka nám navrhla osázení nejen záhonů, ale i celého pozemku. Na zahradu nám přibyly keře v různých uskupeních. Podle návrhu jsme dosadili i ovocné stromy vhodné pro naši nadmořskou výšku, v zahradě se nám třpytí i jezírko. Plochu, která zůstala travnatá, sečeme na krmení králíkům. Park v zahradě zmizel a vznikl prostor, který je příjemný, různorodý, estetický a zároveň i užitkový. Prostě krása! A co je pro nás i dál důležité? Anežčin návrh je základ, o který se stále opíráme. Zároveň nám neubírá prostor pro vlastní kreativitu. Takže jak se měníme my, naše zahrada se mění s námi.",
      name: "Lenka Tkáčová",
    },
    {
      heading: "",
      text: "Máme velkou zahradu a naše představa je postupně ji přetvořit na zejména užitkovou, abychom si na ní mohli s dětmi nejenom hrát, ale také využili dary přírody. Několik let jsme ovšem váhali, protože jsme nevěděli, jak na to. Naštěstí jsem se seznámila s paní, která má se zahradou stejný úmysl jako my, a Anežka jí s realizací pomáhala. Na základě pozitivní zkušenosti jsem neváhala a Anežku brzy oslovila. Přijela k nám a celou zahradu jsme si prošli a popovídali si o naší představě. Anežka nám za pár týdnů vytvořila návrh a nyní se těším na realizaci. Návrh obsahuje mnoho užitečných rad a informací. Velmi si cením možnosti komunikovat s Anežkou i v budoucnosti a konzultovat realizaci zahrady. Spolupráce byla příjemná a ráda Anežku každému doporučím.",
      name: "Blanka Brandová",
    },
    {
      heading: "",
      text: "Jsem velmi spokojená - s Anežkou je skvělá domluva, návrh zahrady vypracovala podle našich představ a zároveň přidala několik vlastních zajímavých nápadů. Díky svým znalostem a zkušenostem umí navrhnout a doporučit i méně obvyklé rostliny, na které bych sama nepřišla. Návrh byl pojatý velmi komplexně, kromě výsadby do něj Anežka zapracovala také prvky vhodné pro děti a možnosti jejich úprav do budoucna. Už jsme Plevel design doporučili příbuzným.",
      name: "Kateřina Maslowská",
    },
    {
      heading: "",
      text: "Anežka Vás ohromí. Je rozhodná, přímá, vtipná a umí poslouchat, co si ve své zahradě přejete a co ne. Má dobré postřehy a kvalifikovaně Vám poradí.",
      name: "Magdalena Křížková",
    },
    {
      heading: "",
      text: "Oceňuju individuální přístup Anežky, prošla s námi celou zahradu a poradila řešení jednotlivých problémů. Doporučila konkrétní rostliny vhodné na různá stanoviště případně úpravu stávajících.",
      name: "Dana Kohl",
    },
    {
      heading: "",
      text: "Tvořivost, odbornost, důkladnost, spolehlivost, ochota, skvělá spolupráce... Paní Horynová je odborník ve svém oboru a ještě k tomu lidsky milá. Jsem ráda za dlouhodobou spolupráci s naším spolkem Mondík.",
      name: "Jiřina Botošová",
    },
    {
      heading: "",
      text: "Anežka nám pomohla učesat náš návrh zahrady. Poradila vhodnou novou výsadbu, nejvhodnější umístění i péči. Poradila, jak vyzrát na problémy v zahradě, které nás trápily a že jich na výsušném svahu je...Zpracovala nám plánek koupacího jezírka, na který bychom si sami netroufli.",
      name: "Lucie Pokorná",
    },
    {
      heading: "",
      text: "S Anežkou je skvělá spolupráce. Zahradu nám navrhla s citem a ohledem na naše přání a potřeby. Realizovali jsme i věci, o kterých jsme ani netušili, že si je přejeme a teď jsme z nich nadšení. Neocenitelné jsou také průběžné rady a konzultace, bez kterých bychom realizaci těžko zvládli.",
      name: "Zuzana Štrynclová",
    },
  ];

  const contact = [
    {
      heading: "Ing. Anežka Horynová",
      text: null,
    },
    {
      heading: "E-mail",
      text: "anezka@pleveldesign.cz",
    },
    {
      heading: "Telefon",
      text: "+420 721 383 618",
    },
    {
      heading: "IČO",
      text: "05538351",
    },
    {
      heading: "Facebook",
      text: "https://www.facebook.com/pleveldesign",
    },
    {
      heading: "Bydliště",
      text: "Kozlovice (okres Frýdek-Místek)",
    },
  ];

  return (
    <div>
      <Navigation />
      <Paragraph>
        <Logostyle src={Logo} />
        <div style={{ textAlign: "center", fontFamily: "Amatic SC", fontSize: "2.2em", lineHeight: "1.1em"   }}>
          Přírodní design pro předzahrádku i celý katastr obce
        </div>
      </Paragraph>
      
      {about.map((about) => {
        return (
          <>
            <div id="O mně">
            <Title>{about.heading}</Title>
            <SubTitle>{about.subheading}</SubTitle>
          
            <Paragraph>{about.text}</Paragraph>
             </div>
          </>
        );
      })}
      {listofservice.map((listofservice) => {
        return (
          <>
            <SubTitle>{listofservice.subheading}</SubTitle>
            <Divisor>
              <List>
                <li>Návrhy přírodních zahrad a krajiny</li>
                <li>Konzultace zahrad i krajinářských projektů</li>
                <li>Pomoc při realizaci (odborný dozor, konzultace v průběhu samorealizace)</li>
                <li>Osazovací plány pro obce (oživení stávajících výsadeb, návrh nových alejí apod)</li>
                <li>Spolupráce při žádosti o dotace (osazovací plány, odborné posudky, konzultace)</li>
                <li>Spolupracuji s organizací Sázíme stromy na osazovacích plánech</li>
              </List>
            </Divisor>
          </>
        );
      })}
      {colab.map((colab) => {
        return (
          <>
            <SubTitle>{colab.subheading}</SubTitle>
            
              <Paragraph>{colab.text}</Paragraph>
              {colab.photo && <Photo src={colab.photo}/>}
            
          </>
        );
      })}
      {experience.map((experience) => {
        return (
          <>
            <SubTitle>{experience.subheading}</SubTitle>
            <Divisor>
              <List>
                <li>2010–⁠2013 Bc. Evoluční a ekologická biologie (PřF UK)</li>
                <li>2013–⁠2015 Ing. Ekologické zemědělství (ČZU)</li>
                <li>2014–⁠2015 Erasmus Wageningen University & Research (Nizozemí)</li>
                <li>2015 Ecological design and permaculture (WUR) – rozsah certifikace PDC</li>
                <li>2015 organizace letní školy Permaculture and Science (ČZU)</li>
                <li>2015 založení projektu BUZZ talks</li>
                <li>2015 ELLS Student Case Study Competition (ČZU) </li>
                <li>2015 Eating City Summer Campus/International platform (Francie)</li>
                <li>2023 Spolupráce se Sázíme stromy, z.ú.</li>
                <li>2023 Arboristická akademie, certifikace ČCA - Pozemní pracovník</li>
              </List>
              
            </Divisor>
            {experience.photo && <Photo src={experience.photo}/>}
          </>
        );
      })}
      {whyplevel.map((whyplevel) => {
        return (
          <>
            <SubTitle>{whyplevel.subheading}</SubTitle>
            
              <Paragraph>{whyplevel.text}</Paragraph>
              
            
          </>
        );
      })}
      <div id="Služby Ceník"></div>    
      <Title>Služby - ceník</Title>
      {service.map((service) => {
        return (
          <>
            
            <SubTitle>{service.heading}</SubTitle>
            
            <Paragraph>{service.text}</Paragraph>
            
          </>
        );
     
      })}  
       
      <div id="Portfolio">
      <Title>Portfolio</Title>
      <Paragraph>
        Zde najdete ukázkové fotky některých mnou navržených, konzultovaných a částečně realizovaných projektů od roku 2012 až do současnosti.
      </Paragraph>
      {portfolio.map((portfolio) => {
        return (
          <>
            <SubTitle>{portfolio.location}</SubTitle>
            
              <Paragraph>{portfolio.description}</Paragraph>
            
            <Gallery items={portfolio.photo} />
          </>
        );
      })}
      </div>
      <div id="Reference">
      <Title>Reference</Title>
      {reference.map((reference) => {
        return (
          <>
            <SubTitle>{reference.heading}</SubTitle>
            <Divisor>
              <Paragraph>{reference.text}</Paragraph>
            </Divisor>
            <Paragraph primary>
              {reference.name} 
            </Paragraph>
          </>
        );
      })}
      </div>

      <div id="Kontakt">
      <Title>Kontakt</Title>
      {contact.map((contact) => {
        return (
          <>
        
            <SubTitle>{contact.heading}</SubTitle>
            <Paragraph>{contact.text}</Paragraph>          
        
          </>
        );
      })}
    </div>
    </div>
  );
}

export default App;
