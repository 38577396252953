import styled from 'styled-components';

const List = styled.a`
    list-style-type: square;
    flex: display;
    flex: 1;
    font-size: 1.2em;
    background-color: ;
    font: 'Roboto Condensed';
    text-align: ${props => props.primary ? "right" : "justify"};
    color: #003300;
    padding-left: 18%;
    padding-right: ${props => props.primary ? "3%" : "15%"};
    line-height: 1.6em;
    display: block;
    text-decoration: none; /* Remove underline for links */
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
        font-size: 1em; /* Adjust font size for tablets */
        padding-left: 12%; /* Reduce left padding for tablets */
        padding-right: ${props => props.primary ? "2%" : "10%"}; /* Adjust right padding */
    }

    @media (max-width: 480px) {
        font-size: 0.9em; /* Adjust font size for mobile */
        padding-left: 8%; /* Further reduce left padding for mobile */
        padding-right: ${props => props.primary ? "1%" : "5%"}; /* Adjust right padding for mobile */
    }
`;

export default List;
