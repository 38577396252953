import styled from 'styled-components';

const Logostyle = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%; /* Default width */
    max-width: 100%; /* Prevents it from exceeding its container */
    height: auto;
    margin-top: 5%;

    /* Media queries for responsiveness */
    @media (max-width: 768px) {
        width: 70%; /* Adjust logo size for tablets */
        margin-top: 16%; /* Adjust margin for better spacing */
    }

    @media (max-width: 480px) {
        width: 90%; /* Further adjust logo size for mobile */
        margin-top: 15%; /* Increase top margin for mobile spacing */
    }
`;

export default Logostyle;
