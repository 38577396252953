import styled from 'styled-components';

const Title = styled.h1`
    font-size: 2.8em;
    text-align: left;
    font-family: 'Amatic SC', cursive;
    color: black;
    padding-left: 15%;
    background-color: #77b300;

    /* Responsive adjustments */
    @media (max-width: 768px) {
        font-size: 2.2em; /* Adjust font size for tablets */
        padding-left: 10%; /* Reduce padding for tablets */
    }

    @media (max-width: 480px) {
        font-size: 1.8em; /* Further adjust font size for mobile */
        padding-left: 5%; /* Reduce padding for mobile */
    }
`;

export default Title;