import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
<<<<<<< HEAD
  width: auto;
=======
  width: 550px;
>>>>>>> 746864bdf88c96104add8c3193cbedbbc9c0eb4c
`;

const Zoomed = styled.div`
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-image: url("${(props) => props.src}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Gallery = ({ items }) => {
  
    return (
    <Carousel
      autoPlay={true}
      dynamicHeight={true}
      infiniteLoop={true}
      showThumbs={false} // Hide thumbnail navigation
      showStatus={false} // Hide status indicator
      showIndicators={true} // Hide slide indicators
      stopOnHover={true} // Stop autoplay on hover
      interval={3000} // Set autoplay interval
      transitionTime={500} // Set transition time
      emulateTouch={true} // Enable touch events
    >
      {items.map((item, index) => (
        <div key={index}>
          <Wrapper>
            <Zoomed src={item.src} />
            
          </Wrapper>
        </div>
      ))}
    </Carousel>
  );
};

export default Gallery;

