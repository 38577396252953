import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: 1.2em;
  font: 'Roboto Condensed';
  text-align: ${props => props.primary ? "right" : "justify"};
  color: #003300;
  padding-left: 15%;
  padding-right: 15%;
  line-height: 1.6em;
  background-color: ;

  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 1em; /* Reduce font size for tablets */
    padding-left: 10%; /* Reduce padding for tablets */
    padding-right: 10%;
  }

  @media (max-width: 480px) {
    font-size: 0.9em; /* Further reduce font size for mobile */
    padding-left: 5%; /* Reduce padding for mobile */
    padding-right: 2%;
  }
`;

export default Paragraph;
