import styled from 'styled-components';

const SubTitle = styled.h1`
    font-size: 2.1em;
    text-align: left;
    font-family: 'Amatic SC', cursive;
    color: #4d4d33;
    padding-left: 15%;
    padding-bottom: 0.1px;

    /* Responsive adjustments */
    @media (max-width: 768px) {
        font-size: 1.8em; /* Adjust font size for tablets */
        padding-left: 10%; /* Reduce padding for tablets */
    }

    @media (max-width: 480px) {
        font-size: 1.5em; /* Further adjust font size for mobile */
        padding-left: 5%; /* Reduce padding for mobile */
    }
`;

export default SubTitle;
